import React from 'react';
import {Datagrid, TextField, DateField} from 'react-admin';
import {cellStyles} from 'ReactAdminPages/Cashout/Styles';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {DefaultList} from 'utils/defaultList/DefaultList';

export const PaymentLogsList = (props: unknown): React.ReactElement => {
    const {t} = useTranslation();
    const classes = cellStyles();
    const {cellClassName, inputw100} = classes;
    const cellClassNameLongInput = clsx(cellClassName, inputw100);

    return <DefaultList {...props}>
        <Datagrid>
            <TextField source="id" cellClassName={cellClassName}/>
            <TextField source="payment_type" cellClassName={cellClassName}/>
            <TextField source="payment_id" cellClassName={cellClassName}/>
            <TextField source="user_type" cellClassName={cellClassName}/>
            <TextField source="user_id" cellClassName={cellClassName}/>
            <TextField source="old_state" cellClassName={cellClassName}/>
            <TextField source="new_state" cellClassName={cellClassNameLongInput}/>
            <TextField source="ip_country" cellClassName={cellClassName}/>
            <TextField source="ip_address" cellClassName={cellClassName}/>
            <DateField showTime source="created_at"
                cellClassName={cellClassName} label={t('date')}/>
        </Datagrid>
    </DefaultList>;
};
