import React from 'react';
import {Datagrid, TextField, DateField} from 'react-admin';
import {cellStyles} from 'ReactAdminPages/Cashout/Styles';
import {CustomImageField} from 'ReactAdminPages/CustomComponents/CustomImageField/CustomImageField';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {EditStateDepositButtons} from 'ReactAdminPages/Deposit/EditStateDepositButtons';
import {DefaultList} from 'utils/defaultList/DefaultList';

export const DepositList = (props: unknown): React.ReactElement => {
    const {t} = useTranslation();
    const classes = cellStyles();
    const {cellClassName, inputw100} = classes;
    const cellClassNameLongInput = clsx(cellClassName, inputw100);

    return <DefaultList {...props}>
        <Datagrid>
            <TextField source="id" cellClassName={cellClassName}/>
            <DateField
                showTime
                source="created_at"
                cellClassName={cellClassName}
                label={t('date')}/>
            <TextField
                source="system_name"
                cellClassName={cellClassName}
                label={t('payment_method')}/>
            <TextField source="amount" cellClassName={cellClassName}/>
            <TextField source="currency" cellClassName={cellClassName}/>
            <TextField source="currency_rate" cellClassName={cellClassName}/>
            <TextField source="partner_name" cellClassName={cellClassName}/>
            <TextField source="customer_email" cellClassName={cellClassNameLongInput}/>
            <TextField source="aasm_state" cellClassName={cellClassName}/>
            <CustomImageField showButton
                source="verified_document"
                label={t('confirmation_screenshot')}
                cellClassName={cellClassName}/>
            <DateField source="paid_at" cellClassName={cellClassName}/>
            <DateField source="confirmed_at" cellClassName={cellClassName}/>
            <DateField source="failed_at" cellClassName={cellClassName}/>
            <EditStateDepositButtons
                label={t('edit_state')}
                cellClassName={cellClassName}/>
        </Datagrid>
    </DefaultList>;
};
