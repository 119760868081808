import React from 'react';
import {Datagrid, TextField, EditButton} from 'react-admin';
import ListToolBar from 'ReactAdminPages/ListToolBar';
import {DefaultList} from 'utils/defaultList/DefaultList';

export const PartnerList = (props: unknown): React.ReactElement => <DefaultList {...props} actions={<ListToolBar/>}>
    <Datagrid>
        <TextField source="id" />
        <TextField source="email"/>
        <EditButton />
    </Datagrid>
</DefaultList>;

