import {RegistrationValidatorReturnI} from 'utils/validators/RegistrationValidatorI';
import {RegistrationFormI} from 'pages/Registration/RegostrationI';

// eslint-disable-next-line prefer-named-capture-group
const EMAIL_REGEX = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/u;

export const validEmail = (str: string): boolean => EMAIL_REGEX.test(str);

const isRequired = (key: string, value: string): object | null => {
    if (value.length === 0) {
        return {[key]: `The ${key[0]} is required.`};
    }
    return null;
};

export const registrationValidator = (data: RegistrationFormI): RegistrationValidatorReturnI => {
    const errors = {};

    for (const [key, value] of Object.entries(data)) {
        const require = isRequired(key, value);

        if (require) {
            Object.assign(errors, require);
        }

        switch (key) {
        case 'password':
        case 'password_confirmation':
            break;
        case 'email':
            if (!validEmail(value)) {
                Object.assign(errors, {email: `The ${key[0]} must be valid email.`});
            }
            break;
        default: break;
        }
    }
    return errors;
};
