import jsonServerProvider from 'ra-data-json-server';
import {DataProvider} from 'react-admin';
import {CreateParams, UpdateParams} from 'ra-core/src/types';
import {fetchUtils} from 'ra-core';
import apiUrl from 'utils/apiUrl/apiUrl';

const dataProvider = jsonServerProvider(process.env.REACT_APP_API_URL);

dataProvider.create = (resource, params) => fetchUtils
    .fetchJson(apiUrl(`/${resource}`), {
        body: JSON.stringify(params.data),
        method: 'POST'
    }).then(({json}) => ({data: json}));

interface ImageDataInterface {
    uri: unknown
    title: string
}

const myDataProvider: DataProvider = {
    ...dataProvider,
    create: (resource, params) => {
        if (!params.data.logo) {
            return dataProvider.create(resource, params);
        }
        return convertFileToBase64(params.data.logo)
            .then(base64Picture => imageData(base64Picture, params))
            .then(transformedNewPicture => dataProvider.create(resource, allParams(params, transformedNewPicture)));
    },
    update: (resource, params) => {
        if (!params.data.logo ||
          params.data.logo && !params.data.logo.rawFile) {
            return dataProvider.update(resource, params);
        }
        return convertFileToBase64(params.data.logo)
            .then(base64Picture => imageData(base64Picture, params))
            .then(transformedNewPicture => dataProvider.update(resource, allParams(params, transformedNewPicture)));
    }
};

const imageData = (base64Picture: unknown, params: CreateParams | UpdateParams): ImageDataInterface => ({title:
        `${params.data.logo.title}`,
uri: base64Picture});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const allParams = (params: CreateParams | UpdateParams, transformedNewPicture: ImageDataInterface): any => ({
    ...params,
    data: {
        ...params.data,
        logo: transformedNewPicture
    }
});

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
});

export default myDataProvider;
