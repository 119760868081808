import React, {useCallback} from 'react';
import IPartner from 'interfaces/IPartner';
import Post from 'utils/fetch/Post';
import {Button} from '@material-ui/core';
import {getUrlWithApi} from 'utils/GetUrlWithApi';
import {useTranslation} from 'react-i18next';

interface IGenerateButtonProps {
    record?: IPartner
    action: string
    callback?: (value: string) => void
}

export const GenerateButton = (props: IGenerateButtonProps): JSX.Element => {
    const {action} = props;
    const {t} = useTranslation();

    const handleClick = useCallback((): void => {
        const {callback, record} = props;
        new Post({
            url: getUrlWithApi(`/partners/${record.id}/generate_${action}`)
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (callback) {
                    callback(response[action]);
                } else {
                    location.reload();
                }
            });
    }, []);

    return <Button
        type="button"
        variant="contained"
        onClick={handleClick}>
        {t(`generate_${action}`)}
    </Button>;
};
