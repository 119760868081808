export const ELEMENT_NOT_FOUND = -1;
export const RESPONSE_STATUS_OK = 200;
export const RESPONSE_STATUS_CREATED = 201;
export const RESPONSE_STATUS_NON_AUTHORITATIVE = 203;
export const RESPONSE_STATUS_NO_CONTENT = 204;
export const RESPONSE_UNAUTHORIZED_STATUS = 401;
export const RESPONSE_STATUS_FORBIDDEN = 403;
export const RESPONSE_STATUS_NOT_FOUND = 404;
export const LOCALES = [
    'en',
    'ru',
    'zh',
    'fr',
    'pl'
];
