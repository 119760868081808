import React, {ReactElement, useCallback} from 'react';
import {
    AASM_STATE_DENIED,
    AASM_STATE_IN_PROGRESS,
    AASM_STATE_SUSPENDED
} from 'utils/cashoutState/StateConsts';
import {changeStateCashout} from 'utils/cashoutState/ChangeStateCashout';
import {EditStateCashoutButtonI} from 'ReactAdminPages/Cashout/EditStateCashoutButton/EditStateCashoutButtonI';
import {ButtonContained} from 'utils/ButtonContained/ButtonContained';

export const EditStateCashoutButton: React.FC<EditStateCashoutButtonI> = ({record, customText}): ReactElement => {
    const changeStateDenied = useCallback(() => changeStateCashout(AASM_STATE_DENIED, record.id), []);
    const changeStateSuspended = useCallback(() => changeStateCashout(AASM_STATE_SUSPENDED, record.id), []);
    const changeStateInProgress = useCallback(() => changeStateCashout(AASM_STATE_IN_PROGRESS, record.id), []);

    const buttons = () => {
        switch (record.aasm_state) {
        case AASM_STATE_IN_PROGRESS:
            return <div className="inline_block">
                <ButtonContained onClick={changeStateDenied} text={'Denied'}/>
                <ButtonContained onClick={changeStateSuspended} text={'Suspended'}/>
            </div>;
        case AASM_STATE_SUSPENDED:
            return <div className="inline_block">
                <ButtonContained onClick={changeStateInProgress} text={'In_progress'}/>
                <ButtonContained onClick={changeStateDenied} text={'Denied'}/>
            </div>;
        default:
            return <div/>;
        }
    };

    return <div>
        <span className="MuiFormLabel-root">{customText}</span>
        {buttons()}
    </div>;
};
