import React from 'react';
import {
    Edit,
    SimpleForm,
    TextField,
    NumberField,
    DateField
} from 'react-admin';
import {useTranslation} from 'react-i18next';

export const RequestLogEdit = (props: unknown): React.ReactElement => {
    const {t} = useTranslation();

    return <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextField source="log_type" />
            <TextField source="body" />
            <TextField source="response" />
            <TextField source="payment_type" />
            <NumberField source="payment_id" />
            <NumberField source="partner_id" />
            <DateField source="created_at" label={t('date')}/>
        </SimpleForm>
    </Edit>;
};

