import ReactAdminHasIntarface from 'interfaces/ReactAdminHasIntarface';
import React, {ReactElement} from 'react';
import FormToolBar from 'ReactAdminPages/FormToolBar';
import {SimpleForm} from 'react-admin';
import omit from 'lodash/fp/omit';

export const SimpleFormLayout = (
    {...props}: ReactAdminHasIntarface
): ReactElement => <SimpleForm {...omit([
    'hasList',
    'hasEdit',
    'hasShow',
    'hasCreate'
], props)} toolbar={<FormToolBar/>}>{props.children}</SimpleForm>;
