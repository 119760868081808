import React from 'react';
import {Datagrid, TextField, ImageField, ReferenceField, EditButton} from 'react-admin';
import {BankButtonToBins} from './BankButtonToBins';
import ListToolBar from 'ReactAdminPages/ListToolBar';
import {DefaultList} from 'utils/defaultList/DefaultList';

export const BankList = (props: unknown): React.ReactElement => <DefaultList
    {...props} actions={<ListToolBar/>}>
    <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ImageField source="logo['url']" label="Logo"/>
        <ReferenceField label="Country" source="country_id" reference="countries" link={false}>
            <TextField source="name" />
        </ReferenceField>
        <BankButtonToBins/>
        <EditButton />
    </Datagrid>
</DefaultList>;

