import React, {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {ImageField} from 'react-admin';
import Button from '@material-ui/core/Button';
import CustomImageFieldI from './CustomImageFieldI';
import './CustomImageField.css';
import {useTranslation} from 'react-i18next';

export const CustomImageField = ({record, source, cellClassName, showButton}: CustomImageFieldI): ReactElement => {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const openDialog = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    const dialogRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                openDialog();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openDialog]);

    return <div>
        <div onClick={openDialog}>
            { showButton
                ? <Button>{t('open')}</Button>
                : <ImageField
                    cellClassName={cellClassName}
                    source={source}
                    record={record}/>
            }
        </div>

        {isOpen &&
                <dialog
                    ref={dialogRef}
                    className="dialog"
                    open
                >
                    <img
                        className="image"
                        src={record.verified_document}/>
                </dialog>
        }
    </div>;
};
