import React from 'react';
import {Create, SimpleForm, TextInput, NumberInput} from 'react-admin';

export const DepositEditCreate = (props: unknown): React.ReactElement => <Create {...props}>
    <SimpleForm>
        <NumberInput source="system_id" />
        <NumberInput source="partner_id" />
        <NumberInput source="customer_id" />
        <NumberInput source="secure_id" />
        <TextInput source="amount" />
        <TextInput source="currency" />
        <TextInput source="fee_amount" />
        <NumberInput source="deposit_id" />
        <TextInput source="aasm_state" />
        <NumberInput source="wallet_id" />
    </SimpleForm>
</Create>;

