import React, {ReactElement, useCallback} from 'react';
import {EditButtonProps} from 'react-admin';
import DefaultCustomFieldI from 'interfaces/DefaultCustomFieldI';
import {ButtonContained} from 'utils/ButtonContained/ButtonContained';
import {resendCallback} from 'utils/ResendCallback';
import {useTranslation} from 'react-i18next';
import {RequestLogInterface} from 'ReactAdminPages/RequestLog/RequestLogInterface';

interface EditStateDepositButtonsI extends DefaultCustomFieldI {
    record?: EditButtonProps & RequestLogInterface
    cellClassName?: string
    customText?: string
}

export const ResendCallbackButton: React.FC<EditStateDepositButtonsI> = ({record}): ReactElement => {
    const resend = useCallback(() => resendCallback(record.id), []);
    const {t} = useTranslation();

    return <> {record.log_type === 'Send' && <ButtonContained onClick={resend} text={t('resend_callback')}/>}</>;
};
