import React, {ReactElement, useCallback} from 'react';
import {
    AASM_STATE_AUTO_WAITING,
    AASM_STATE_DENIED,
    AASM_STATE_COMPLETED,
    AASM_STATE_NOT_VALID,
    AASM_STATE_SUSPENDED,
    AASM_STATE_WAITING
} from 'utils/cashoutState/StateConsts';
import {EditButtonProps} from 'react-admin';
import {DepositI} from 'ReactAdminPages/Deposit/DepositI';
import DefaultCustomFieldI from 'interfaces/DefaultCustomFieldI';
import {changeStateCashout} from 'utils/cashoutState/ChangeStateCashout';
import {ButtonContained} from 'utils/ButtonContained/ButtonContained';

interface EditStateDepositButtonsI extends DefaultCustomFieldI {
    record?: EditButtonProps & DepositI
    cellClassName?: string
    customText?: string
}

export const EditStateDepositButtons: React.FC<EditStateDepositButtonsI> = ({record, customText}): ReactElement => {
    const changeStateDenied = useCallback(
        () => changeStateCashout(AASM_STATE_DENIED, record.cashout_id), []
    );
    const changeStateSuspended = useCallback(
        () => changeStateCashout(AASM_STATE_SUSPENDED, record.cashout_id), []
    );
    const changeStateWaiting = useCallback(
        () => changeStateCashout(AASM_STATE_WAITING, record.cashout_id), []
    );
    const changeStateNotValid = useCallback(
        () => changeStateCashout(AASM_STATE_NOT_VALID, record.cashout_id), []
    );
    const changeStateCompleted = useCallback(
        () => changeStateCashout(AASM_STATE_COMPLETED, record.cashout_id), []
    );

    const buttons = () => {
        switch (record.aasm_state) {
        case AASM_STATE_WAITING:
            return <div className="inline_block">
                <ButtonContained onClick={changeStateCompleted} text={'Completed'}/>
                <ButtonContained onClick={changeStateDenied} text={'Denied'}/>
                <ButtonContained onClick={changeStateSuspended} text={'Suspended'}/>
            </div>;
        case AASM_STATE_AUTO_WAITING:
            return <div className="inline_block">
                <ButtonContained onClick={changeStateNotValid} text={'Not_valid'}/>
                <ButtonContained onClick={changeStateCompleted} text={'Completed'}/>
                <ButtonContained onClick={changeStateDenied} text={'Denied'}/>
                <ButtonContained onClick={changeStateSuspended} text={'Suspended'}/>
            </div>;
        case AASM_STATE_NOT_VALID:
            return <div className="inline_block">
                <ButtonContained onClick={changeStateCompleted} text={'Completed'}/>
                <ButtonContained onClick={changeStateDenied} text={'Denied'}/>
                <ButtonContained onClick={changeStateSuspended} text={'Suspended'}/>
            </div>;
        case AASM_STATE_COMPLETED:
            return <div className="inline_block">
                <ButtonContained onClick={changeStateDenied} text={'Denied'}/>
                <ButtonContained onClick={changeStateSuspended} text={'Suspended'}/>
            </div>;
        case AASM_STATE_SUSPENDED:
            return <div className="inline_block">
                <ButtonContained onClick={changeStateCompleted} text={'Completed'}/>
                <ButtonContained onClick={changeStateDenied} text={'Denied'}/>
                <ButtonContained onClick={changeStateWaiting} text={'Waiting'}/>
                <ButtonContained onClick={changeStateNotValid} text={'Not_valid'}/>
            </div>;
        default:
            return <div/>;
        }
    };

    return <div>
        <span className="MuiFormLabel-root">{customText}</span>
        {buttons()}
    </div>;
};
