import React from 'react';
import {BooleanInput, Edit, required, TextField, TextInput} from 'react-admin';
import TokenBlock from './TokenBlock';
import SecretBlock from 'ReactAdminPages/Partner/SecretBlock';
import {GenerateButton} from 'ReactAdminPages/Partner/GenerateButton';
import {SimpleFormLayout} from 'ReactAdminPages/FormLayout/SimpleFormLayout';

export const PartnerEdit = (props: unknown): React.ReactElement => <Edit {...props}>
    <SimpleFormLayout {...props}>
        <TextInput disabled source="id" />
        <TextInput source="name" validate={required()}/>
        <TextInput source="email" validate={required()}/>
        <TextInput source="notify_url" validate={required()}/>
        <TextInput source="finish_url" validate={required()}/>
        <TextInput source="cancel_url" validate={required()}/>
        <BooleanInput source="active" />
        <TextField source="password"/>
        <GenerateButton action="password"/>
        <TokenBlock/>
        <SecretBlock/>
    </SimpleFormLayout>
</Edit>;

