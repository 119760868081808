import React from 'react';
import {
    DateField,
    TextField,
    Show,
    ReferenceManyField,
    Datagrid,
    SimpleShowLayout
} from 'react-admin';
import {DefaultPropsI} from 'interfaces';
import {CustomImageField} from 'ReactAdminPages/CustomComponents/CustomImageField/CustomImageField';
import {TableHead, TableRow, TableCell, Table} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {BackButton} from 'ReactAdminPages/BackButton';
import {cellStyles} from 'ReactAdminPages/Cashout/Styles';
import {RedirectToPaymentLogs} from 'ReactAdminPages/PaymentLogs/RedirectToPaymentLogs';

export const CashoutDetails = (props: DefaultPropsI): React.ReactElement => {
    const {t} = useTranslation();
    const classes = cellStyles();
    const {cellClassName} = classes;
    return <Show {...props}>
        <SimpleShowLayout>
            <p>{t('deposits')}</p>
            <ReferenceManyField
                label={t('deposits')}
                reference="deposits"
                target="cashout_id">
                <Datagrid>
                    <TextField source="id" cellClassName={cellClassName}/>
                    <TextField
                        source="system_name"
                        cellClassName={cellClassName}
                        label={t('payment_method')}/>
                    <TextField source="partner_name" cellClassName={cellClassName}/>
                    <TextField
                        source="customer_email"
                        cellClassName={cellClassName}
                        label={t('user_email')}
                    />
                    <DateField
                        source="created_at"
                        label={t('payment_creation_date')}
                        showTime
                        cellClassName={cellClassName}/>
                    <TextField
                        source="amount"
                        label={t('amount')}
                        cellClassName={cellClassName}/>
                    <TextField source="currency" cellClassName={cellClassName}/>
                    <TextField source="currency_rate" cellClassName={cellClassName}/>
                    <CustomImageField showButton
                        source="verified_document"
                        label={t('confirmation_screenshot')}
                        cellClassName={cellClassName}/>
                    <DateField source="paid_at" showTime cellClassName={cellClassName}/>
                    <DateField source="confirmed_at" showTime cellClassName={cellClassName}/>
                    <DateField source="failed_at" showTime cellClassName={cellClassName}/>
                    <TextField
                        source="aasm_state"
                        label={t('payment_state')}
                        cellClassName={cellClassName}/>
                    <DateField
                        source="updated_at"
                        showTime
                        label={t('last_update_status')}
                        cellClassName={cellClassName}/>
                    <RedirectToPaymentLogs isDeposit/>
                </Datagrid>
            </ReferenceManyField>

            <p>{t('cashout')}</p>
            <RedirectToPaymentLogs/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>{t('partner_name')}</TableCell>
                        <TableCell>{t('cashout_user_email')}</TableCell>
                        <TableCell>{t('amount')}</TableCell>
                        <TableCell>{t('currency')}</TableCell>
                        <TableCell>{t('payment_state')}</TableCell>
                        <TableCell>{t('payment_creation_date')}</TableCell>
                        <TableCell>{t('payment_method')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TextField source="id"/></TableCell>
                        <TableCell><TextField source="partner_name"/></TableCell>
                        <TableCell><TextField source="customer_email"/></TableCell>
                        <TableCell><TextField source="amount" /></TableCell>
                        <TableCell><TextField source="currency"/></TableCell>
                        <TableCell><TextField source="aasm_state"/></TableCell>
                        <TableCell><DateField source="created_at" showTime/></TableCell>
                        <TableCell><TextField source="system_name"/></TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            <p>{t('wallet')}</p>

            <ReferenceManyField
                label={t('cashout_wallet')}
                reference="wallets"
                target="id"
                source="wallet_id">
                <Datagrid>
                    <TextField sortable={false} source="id" />
                    <TextField sortable={false} source="account" />
                    <TextField sortable={false} source="bank_name" />
                    <TextField sortable={false} source="total_cashout" />
                    <TextField sortable={false} source="total_deposit" />
                    <DateField sortable={false} source="created_at" showTime/>
                </Datagrid>
            </ReferenceManyField>
            <BackButton/>
        </SimpleShowLayout>
    </Show>;
};
