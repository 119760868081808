import Post from 'utils/fetch/Post';
import apiUrl from 'utils/apiUrl/apiUrl';

export const resendCallback = (record_id: string | number): void => {
    new Post({
        url: apiUrl(`/request_log/${record_id}/resend`)
    })
        .execute()
        .then(response => {
            if (response.ok) {
                window.location.reload();
            }
        });
};
