import {TextField} from '@material-ui/core';
import React from 'react';
import {InputI} from 'ReactAdminPages/Input/InputI';

export const Input = ({
    meta: {touched, error},
    input: inputProps,
    ...props
}: InputI): JSX.Element => <TextField
    error={Boolean(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
/>;
