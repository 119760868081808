import React from 'react';
import {SaveButton, Toolbar} from 'react-admin';
import {BackButton} from './BackButton';

interface IPropsFormToolBar {
    buttons?: [React.ReactElement]
}

const FormToolBar = (props: IPropsFormToolBar): React.ReactElement => <Toolbar {...props}>
    <SaveButton
        label="Save"
        redirect="show"
        submitOnEnter={true}
    />
    <BackButton></BackButton>
    {props.buttons}
</Toolbar>;

export default FormToolBar;
