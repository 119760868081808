import {List} from 'react-admin';
import React, {ReactElement} from 'react';
import {ListPagination} from 'utils/listPagination/ListPagination';

export default interface ReactAdminListIntarface {
    actions?: ReactElement;
    children: ReactElement;
    filters?: ReactElement[];
}

export const DefaultList = (
    {...props}: ReactAdminListIntarface
): ReactElement => <List
    {...props}
    exporter={false}
    sort={{field: 'id', order: 'DESC'}}
    pagination={<ListPagination />}
    bulkActionButtons={false}
    actions={props.actions}>
    {props.children}
</List>;
