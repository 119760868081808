import {createTheme} from '@material-ui/core/styles';

export const LightTheme = createTheme({
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                backgroundColor: '#2196f3',
                color: '#fff'
            }
        }
    },
    palette: {
        type: 'light'
    }
});

export const DarkTheme = createTheme({
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                backgroundColor: '#1e4c9a',
                color: '#fff'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff'
            }
        },
        MuiDrawer: {
            paper: {
                paddingTop: '20px'
            }
        },
        MuiFilledInput: {
            underline: {
                '&:after': {
                    borderBottomColor: '#bf9f00'
                }
            }
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#bf9f00'
                }
            }
        },
        MuiTypography: {
            colorPrimary: {
                color: '#fff'
            }
        }
    },
    palette: {
        type: 'dark'
    }
});
