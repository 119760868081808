import React from 'react';
import ReactDOM from 'react-dom';
import App from 'pages/App/App';
import {I18nextProvider} from 'react-i18next';
import i18n from 'I18n/i18n';

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <App/>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
