import React, {ReactNode, useCallback} from 'react';
import {LightTheme} from 'ReactAdminPages/Theme/Theme';
import {ThemeProvider} from '@material-ui/styles';
import {createTheme} from '@material-ui/core/styles';
import {Avatar, Button, Card, CardActions, CircularProgress} from '@material-ui/core';
import {Form, Field} from 'react-final-form';
import {registrationValidator} from 'utils/validators/RegistrationValidator';
import LockIcon from '@material-ui/icons/Lock';
import {Input} from 'ReactAdminPages/Input/Input';
import {RegistrationFormI} from 'pages/Registration/RegostrationI';
import './Registration.css';
import {FormApi} from 'final-form';
import useRegistration from 'pages/Registration/UseRegistration/UseRegistration';
import {useNotify, useSafeSetState} from 'ra-core';

const Registration = (): ReactNode => {
    const [loading, setLoading] = useSafeSetState(false);
    const theme = createTheme(LightTheme);
    const registration = useRegistration();
    const notify = useNotify();

    const register = (
        values: RegistrationFormI,
        formApi: FormApi<RegistrationFormI, Partial<RegistrationFormI>>
    ): void => {
        setLoading(true);
        registration(values, '/login')
            .then(() => {
                formApi.reset();
                setLoading(false);
            }).catch(error => {
                notify(error);
            });
    };

    const handleSubmitForm = useCallback((data, form) => {
        register(data, form);
    }, []);

    const registrationForm = useCallback(({handleSubmit}) => <form
        id="reg_form"
        onSubmit={handleSubmit}
        className="registration_form"
        autoComplete={'off'}
        noValidate>
        <Field
            id="username"
            name="username"
            placeholder="Username"
            component={Input}
            className="registration_input"/>
        <Field
            id="email"
            name="email"
            placeholder="Email"
            component={Input}
            className="registration_input"/>
        <Field
            id="password"
            name="password"
            placeholder="Password"
            type="password"
            component={Input}
            className="registration_input"/>
        <Field
            id="password_confirmation"
            name="password_confirmation"
            placeholder="Password confirmation"
            type="password"
            component={Input}
            className="registration_input"/>
        <CardActions>
            <Button
                variant="contained"
                type="submit"
                color="primary"
                className="registration_button"
            >
                {loading && <CircularProgress
                    className="registration_icon"
                    size={18} thickness={2}/>}
                    Registration
            </Button>
        </CardActions>
    </form>
    , []);

    return <ThemeProvider theme={theme}>
        <div className="registration_main">
            <Card className="registration_card">
                <div className="registration_avatar">
                    <Avatar className="registration_icon">
                        <LockIcon />
                    </Avatar>
                </div>
                <Form
                    onSubmit={handleSubmitForm}
                    validate={registrationValidator}
                    render={registrationForm}/>
            </Card>
        </div>
    </ThemeProvider>;
};

export default Registration;
