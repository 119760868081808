import {objectToURIComponent} from './UriHelper';
import {RequestI} from 'utils/fetch/HttpI';

class HttpFetch {
    public options?: RequestInit;

    constructor(options?: RequestInit) {
        this.options = {
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            ...options
        };
    }

    errorStatusCallback;

    get cache(): RequestCache {
        return this.options.cache;
    }

    set cache(value: RequestCache) {
        this.options.cache = value;
    }

    get credentials(): RequestCredentials {
        return this.options.credentials;
    }

    set credentials(value: RequestCredentials) {
        this.options.credentials = value;
    }

    get mode(): RequestMode {
        return this.options.mode;
    }

    set mode(value: RequestMode) {
        this.options.mode = value;
    }

    get redirect(): RequestRedirect {
        return this.options.redirect;
    }

    set redirect(value: RequestRedirect) {
        this.options.redirect = value;
    }

    get referrerPolicy(): ReferrerPolicy {
        return this.options.referrerPolicy;
    }

    set referrerPolicy(value: ReferrerPolicy) {
        this.options.referrerPolicy = value;
    }

    get headers(): HeadersInit {
        return this.options.headers;
    }

    set headers(value: HeadersInit) {
        this.options.headers = {
            ...this.options.headers,
            ...value
        };
    }

    convertParamsByContentType(data: {[key: string]: string}): string {
        switch (this.options.headers['Content-Type']) {
        case 'application/json':
            return JSON.stringify(data);
        case 'application/x-www-form-urlencoded':
            return objectToURIComponent(data);
        default:
            return JSON.stringify(data);
        }
    }

    request(
        url: string,
        method: string,
        params: {[key: string]: string},
        headers: {[key: string]: string}
    ): Promise<Response> {
        const fetchParams = {
            ...this.options,
            headers: {
                ...this.options.headers,
                ...headers
            },
            method
        };

        if (params) {
            fetchParams.body = this.convertParamsByContentType(params);
        }

        return fetch(url, fetchParams)
            .then(response => {
                if (!response.ok) {
                    this.errorStatusCallback && this.errorStatusCallback(response);
                }

                return response;
            });
    }

    get({url, params = null, headers = {}}: RequestI): Promise<Response> {
        return this.request(url, 'GET', params, headers);
    }

    post({url, params = null, headers = {}}: RequestI): Promise<Response> {
        return this.request(url, 'POST', params, headers);
    }

    put({url, params = null, headers = {}}: RequestI): Promise<Response> {
        return this.request(url, 'PUT', params, headers);
    }

    delete({url, params = null, headers = {}}: RequestI): Promise<Response> {
        return this.request(url, 'DELETE', params, headers);
    }
}

const httpFetch = new HttpFetch();

export {HttpFetch};

export default httpFetch;
