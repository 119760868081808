import React, {ReactElement} from 'react';
import {Button} from '@material-ui/core';


interface ButtonContainedInterface {
    onClick(): void
    text: string
}

export const ButtonContained = ({onClick, text}: ButtonContainedInterface): ReactElement => <Button
    variant="contained"
    onClick={onClick}>
    {text}
</Button>;
