import React, {ReactElement} from 'react';
import {Admin, Login, Resource} from 'react-admin';
import PaymentsIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Dashboard from 'ReactAdminPages/Dashboard/Dashboard';
import PartnersIcon from '@material-ui/icons/Group';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ListIcon from '@material-ui/icons/List';
import AuthProvider from 'providers/AuthProvider/AuthProvider';
import CustomRoutes from 'ReactAdminPages/CustomRoutes/СustomRoutes';
import {LightTheme} from 'ReactAdminPages/Theme/Theme';
import {createBrowserHistory} from 'history';
import {SystemList} from 'ReactAdminPages/Systems/SystemList';
import {SystemCreate} from 'ReactAdminPages/Systems/SystemCreate';
import {SystemEdit} from 'ReactAdminPages/Systems/SystemEdit';
import {DepositList} from 'ReactAdminPages/Deposit/DepositList';
import {DepositEditCreate} from 'ReactAdminPages/Deposit/DepositEditCreate';
import {CashoutList} from 'ReactAdminPages/Cashout/CashoutList';
import {BankList} from 'ReactAdminPages/Banks/BankList';
import {BankEdit} from 'ReactAdminPages/Banks/BankEdit';
import {BankCreate} from 'ReactAdminPages/Banks/BankCreate';
import dataProvider from 'providers/DataProvider/DataProvider';
import {CashoutDetails} from 'ReactAdminPages/Cashout/CashoutDetails';
import {BankBinsList} from 'ReactAdminPages/BankBins/BankBinsList';
import {BankBinEdit} from 'ReactAdminPages/BankBins/BankBinEdit';
import {BankBinCreate} from 'ReactAdminPages/BankBins/BankBinCreate';
import {PartnerList} from 'ReactAdminPages/Partner/PartnerList';
import {PartnerCreate} from 'ReactAdminPages/Partner/PartnerCreate';
import {PartnerEdit} from 'ReactAdminPages/Partner/PartnerEdit';
import {PendingDepositList} from 'ReactAdminPages/PendingDeposit/PendingDepositList';
import {WalletList} from 'ReactAdminPages/Wallet/WalletList';
import {RequestLogList} from 'ReactAdminPages/RequestLog/RequestLogList';
import {RequestLogEdit} from 'ReactAdminPages/RequestLog/RequestLogEdit';
import {PaymentLogsList} from 'ReactAdminPages/PaymentLogs/PaymentLogsList';

const history = createBrowserHistory();

const App = (): ReactElement => <Admin
    theme={LightTheme}
    loginPage={Login}
    history={history}
    customRoutes={CustomRoutes}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={AuthProvider}>
    <Resource
        name="partners"
        icon={PartnersIcon}
        list={PartnerList}
        create={PartnerCreate}
        edit={PartnerEdit}
    />
    <Resource
        name="banks"
        icon={AccountBalanceIcon}
        list={BankList}
        edit={BankEdit}
        create={BankCreate}
    />
    <Resource
        name="bank_bins"
        icon={AccountBalanceIcon}
        list={BankBinsList}
        edit={BankBinEdit}
        create={BankBinCreate}
    />
    <Resource
        name="systems"
        icon={PaymentsIcon}
        list={SystemList}
        edit={SystemEdit}
        create={SystemCreate}
    />
    <Resource name="countries"/>
    <Resource
        name="deposits"
        icon={PaymentsIcon}
        list={DepositList}
        edit={DepositEditCreate}/>
    <Resource
        options={{label: 'Pending deposits'}}
        name="pending_deposits"
        icon={PaymentsIcon}
        list={PendingDepositList}/>
    <Resource
        name="cashouts"
        icon={PaymentsIcon}
        list={CashoutList}
        edit={CashoutDetails}/>
    <Resource
        name="wallets"
        icon={AccountBalanceWalletIcon}
        list={WalletList}/>
    <Resource
        name="request_log"
        icon={SyncAltIcon}
        list={RequestLogList}
        edit={RequestLogEdit}/>
    <Resource
        options={{label: 'Payment Logs Cashouts'}}
        name="payment_logs/cashouts"
        icon={ListIcon}
        list={PaymentLogsList}/>
    <Resource
        options={{label: 'Payment Logs Deposits'}}
        name="payment_logs/deposits"
        icon={ListIcon}
        list={PaymentLogsList}/>
</Admin>;
export default App;
