import React from 'react';
import {Datagrid, TextField, BooleanField, ImageField} from 'react-admin';
import ListToolBar from 'ReactAdminPages/ListToolBar';
import {DefaultList} from 'utils/defaultList/DefaultList';

export const SystemList = (props: unknown): React.ReactElement => <DefaultList
    {...props} actions={<ListToolBar/>}>
    <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        <ImageField source="logo['url']" label="Logo"/>
        <BooleanField source="enabled" />
        <BooleanField source="account_image" />
        <BooleanField source="with_bank" />
    </Datagrid>
</DefaultList>;

