import React from 'react';
import {
    BooleanInput,
    ImageField,
    ImageInput,
    required,
    TextInput,
    TranslatableInputs,
    SelectInput
} from 'react-admin';
import {LOCALES} from 'utils/Consts';
import {useTranslation} from 'react-i18next';
import ReactAdminHasIntarface from 'interfaces/ReactAdminHasIntarface';
import {SimpleFormLayout} from 'ReactAdminPages/FormLayout/SimpleFormLayout';

export const SystemForm = ({...props}: ReactAdminHasIntarface): React.ReactElement => {
    const {t} = useTranslation();
    const paymentType = [{id: 'default', name: t('default')}, {id: 'image', name: t('by_image')}];

    return <SimpleFormLayout {...props}>
        <TextInput disabled source="id" />
        <TextInput source="name" validate={required()} />
        <TextInput source="slug" />
        <ImageInput source="logo" validate={required()} accept=".svg,.png">
            <ImageField source="url" title="title" />
        </ImageInput>
        <BooleanInput source="enabled" />
        <BooleanInput source="account_image" />
        <BooleanInput source="with_bank" />
        <BooleanInput source="p2p_same_country"/>
        <TextInput source="min_cashout_limit"/>
        <TextInput source="max_cashout_limit"/>
        <TextInput source="min_deposit_limit"/>
        <TextInput source="max_deposit_limit"/>
        <TextInput source="wallet_regexp" />
        <TextInput source="wallet_placeholder" />
        <SelectInput source="payment_type" choices={paymentType} />
        <TranslatableInputs locales={LOCALES}>
            <TextInput
                multiline fullWidth
                source="deposit_descriptions_attributes"
                label={t('deposit_description_title')} />
            <TextInput source="deposit_comments_attributes"
                label={t('payment_comment')} />
            <TextInput source="account_labels_attributes"
                label={t('account_label_title')} />
        </TranslatableInputs>
    </SimpleFormLayout>;
};
