import React from 'react';
import {Datagrid, TextField, NumberField, DateField} from 'react-admin';
import {EditStateCashoutButton} from 'ReactAdminPages/Cashout/EditStateCashoutButton/EditStateCashoutButton';
import {CustomImageField} from 'ReactAdminPages/CustomComponents/CustomImageField/CustomImageField';
import {RedirectToCashoutDetails} from 'ReactAdminPages/Cashout/RedirectToCashoutDetails';
import {cellStyles} from 'ReactAdminPages/Cashout/Styles';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {DefaultList} from 'utils/defaultList/DefaultList';

export const CashoutList = (props: unknown): React.ReactElement => {
    const {t} = useTranslation();
    const classes = cellStyles();
    const {cellClassName, inputw100} = classes;
    const cellClassNameLongInput = clsx(cellClassName, inputw100);

    return <DefaultList {...props}>
        <Datagrid basePath={'/cashouts'}>
            <TextField cellClassName={cellClassName} source="id" />
            <DateField
                showTime
                cellClassName={cellClassName}
                source="created_at"
                label={t('payment_creation_date')}/>
            <TextField
                cellClassName={cellClassName}
                source="aasm_state"
                label={t('payment_state')}/>
            <NumberField
                cellClassName={cellClassName}
                source="system_name"
                label={t('payment_method')}/>
            <TextField
                cellClassName={cellClassName}
                source="wallet_account"
                label={t('wallet_number')}/>
            <TextField
                cellClassName={cellClassName}
                source="amount"
                label={t('amount')}/>
            <TextField
                cellClassName={cellClassName}
                source="partner_name"
                label={t('partner_name')}/>
            <TextField
                cellClassName={cellClassNameLongInput}
                source="customer_email"
                label={t('cashout_user_email')}/>
            <NumberField
                cellClassName={cellClassName}
                source="failed_deposit_count"
                label={t('failed_deposit_count')}/>
            <CustomImageField
                cellClassName={cellClassName}
                source="verified_document"
                label={t('confirmation_screenshot')}/>
            <EditStateCashoutButton
                label={t('edit_state')}
                cellClassName={cellClassName}/>
            <RedirectToCashoutDetails/>
        </Datagrid>
    </DefaultList>;
};

