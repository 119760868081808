import {makeStyles} from '@material-ui/core/styles';

export const cellStyles = makeStyles({
    cellClassName: {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '2px',
        textAlign: 'center'
    },
    inputw100: {
        maxWidth: '100px',
        wordBreak: 'break-all'
    }
});
