import {AuthProviderLoginParamsI} from 'providers/AuthProvider/AuthProviderI';
import Post from 'utils/fetch/Post';
import Delete from 'utils/fetch/Delete';
import {
    RESPONSE_STATUS_FORBIDDEN,
    RESPONSE_STATUS_NO_CONTENT,
    RESPONSE_UNAUTHORIZED_STATUS
} from 'utils/Consts';
import {RegistrationFormI} from 'pages/Registration/RegostrationI';

const OK_STATUSES = [RESPONSE_STATUS_FORBIDDEN, RESPONSE_UNAUTHORIZED_STATUS];

export default {
    // Called when the user navigates to a new location, to check for authentication
    checkAuth: (): Promise<void> => localStorage.getItem('username')
        ? Promise.resolve()
        : Promise.reject(new Error('bad..')).catch(),
    // Called when the API returns an error
    checkError: (status: number): Promise<void> => {
        if (OK_STATUSES.includes(status)) {
            localStorage.removeItem('username');
            return Promise.reject(new Error('bad..')).catch();
        }
        return Promise.resolve();
    },
    // Called when the user navigates to a new location, to check for permissions / roles
    getPermissions: (): Promise<void> => Promise.resolve(),

    // Called when the user attempts to log in
    login: (params: AuthProviderLoginParamsI): Promise<void> => new Post({
        params: {admin_user:
                    {
                        password: params.password,
                        remember_me: true,
                        username: params.username
                    }},
        url: `${process.env.REACT_APP_API_URL}/admin_users/sign_in`
    })
        .execute()
        .then(response => response.json())
        .then(response => {
            const {error} = response;

            if (error) {
                throw new Error(error);
            } else {
                localStorage.setItem('username', params.username);
                localStorage.setItem('token', JSON.stringify(response.token));
                return Promise.resolve();
            }
        }),

    logout: (): Promise<void> => new Delete({url: `${process.env.REACT_APP_API_URL}/admin_users/sign_out`})
        .execute()
        .then(response => {
            if (response.status === RESPONSE_STATUS_NO_CONTENT) {
                localStorage.removeItem('username');
                localStorage.removeItem('token');
                return Promise.resolve();
            }
            return response.json();
        })
        .catch(response => {
            throw new Error(response.errors[Object.keys(response.errors)[0]][0]);
        }),

    registration: (values: RegistrationFormI): Promise<void> => new Post({
        params: {admin_user: values},
        url: `${process.env.REACT_APP_API_URL}/admin_users`
    })
        .execute()
        .then(response => response.json())
        .then(response => {
            const {errors} = response;

            if (errors) {
                throw new Error(errors[Object.keys(errors)[0]][0]);
            } else {
                return Promise.resolve();
            }
        })
};
