import React, {useCallback} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IModalProps {
    open: boolean
    onClose: () => void
    body: string
    title: string
}

export const Modal = (props: IModalProps): React.ReactElement => {
    const handleClose = useCallback(() => props.onClose(), []);

    return <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <p style={{margin: '6px'}}>{props.body}</p>
    </Dialog>;
};

export default Modal;
