import React from 'react';
import {CreateButton, TopToolbar} from 'react-admin';

interface IPropsListToolBar {
    elements?: [React.ReactElement]
}

const ListToolBar = (props: IPropsListToolBar): React.ReactElement => <TopToolbar>
    {props.elements && props.elements.map(el => el)}
    <CreateButton/>
</TopToolbar>;

export default ListToolBar;
