import React from 'react';
import {Datagrid, TextField, NumberField, DateField, ShowButton} from 'react-admin';
import {DefaultList} from 'utils/defaultList/DefaultList';
import {useTranslation} from 'react-i18next';
import {ResendCallbackButton} from 'ReactAdminPages/RequestLog/ResendCallbackButton';

export const RequestLogList = (props: unknown): React.ReactElement => {
    const {t} = useTranslation();

    return <DefaultList {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="log_type" />
            <TextField source="response" />
            <TextField source="payment_type" />
            <NumberField source="payment_id" />
            <NumberField source="partner_id" />
            <DateField source="created_at" label={t('date')}/>
            <ResendCallbackButton/>
            <ShowButton />
        </Datagrid>
    </DefaultList>;
};

