import {useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {RegistrationFormI} from 'pages/Registration/RegostrationI';
import {useAuthProvider} from 'react-admin';

type Registration = (params: RegistrationFormI, pathName?: string) => Promise<void>;

const useRegistration = (): Registration => {
    const authProvider = useAuthProvider();
    const location = useLocation();
    const locationState = location.state;
    const history = useHistory();
    const dispatch = useDispatch();
    const nextPathName = locationState && locationState.nextPathname;
    const nextSearch = locationState && locationState.nextSearch;

    return useCallback(
        (params: RegistrationFormI, pathName) => authProvider.registration(params).then(ret => {
            const redirectUrl = pathName ? pathName : '/';
            history.push(redirectUrl);
            return ret;
        }),
        [
            authProvider,
            history,
            nextPathName,
            nextSearch,
            dispatch
        ]
    );
};

export default useRegistration;
