import React, {ReactElement} from 'react';
import {
    ImageField,
    ImageInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from 'react-admin';
import ReactAdminHasIntarface from 'interfaces/ReactAdminHasIntarface';
import {SimpleFormLayout} from 'ReactAdminPages/FormLayout/SimpleFormLayout';

const COUNTRIES_PER_PAGE = 300;

export const BankForm = ({...props}: ReactAdminHasIntarface): ReactElement => <SimpleFormLayout {...props}>
    <TextInput disabled source="id" />
    <TextInput source="name" validate={required()} />
    <ImageInput source="logo" validate={required()} accept=".svg,.png">
        <ImageField source="url" title="title" />
    </ImageInput>
    <ReferenceInput label="Country" source="country_id" reference="countries" perPage={COUNTRIES_PER_PAGE}>
        <SelectInput optionText="name" />
    </ReferenceInput>
</SimpleFormLayout>;
