import React from 'react';
import {
    Datagrid,
    ReferenceField,
    TextField,
    SearchInput,
    TextInput,
    SelectInput,
    ReferenceInput,
    FilterButton
} from 'react-admin';
import ListToolBar from 'ReactAdminPages/ListToolBar';
import {DefaultList} from 'utils/defaultList/DefaultList';

const COUNTRIES_PER_PAGE = 300;

const filters = [
    <SearchInput key="filter_bin" source="bin" alwaysOn />,
    <TextInput key="filter_bank_name" label="Bank" source="bank_name" defaultValue="" />,
    <ReferenceInput
        key="filter_country_id"
        label="Country"
        source="country_id"
        reference="countries"
        perPage={COUNTRIES_PER_PAGE}
        defaultValue=""
    >
        <SelectInput optionText="name" />
    </ReferenceInput>
];

export const BankBinsList = (props: unknown): React.ReactElement => <DefaultList
    {...props} filters={filters} actions={<ListToolBar elements={[<FilterButton key="filter-button"/>]}/>}>
    <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField label="bank" source="bank_id" reference="banks" link={false}>
            <TextField source="name" />
        </ReferenceField>
        <TextField source="bin" />
    </Datagrid>
</DefaultList>;
