import React, {useCallback, useState} from 'react';
import IPartner from 'interfaces/IPartner';
import Modal from '../Modal';
import {useTranslation} from 'react-i18next';
import {GenerateButton} from 'ReactAdminPages/Partner/GenerateButton';

interface ITokenBlockProps {
    record?: IPartner
}

export const TokenBlock = (props: ITokenBlockProps): React.ReactElement => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [token, setToken] = useState('');

    const onSuccess = useCallback(value => {
        setToken(value);
        setOpen(true);
    }, []);

    return <>
        <Modal open={open}
            body={t('copy_token.body', {token})}
            onClose={useCallback(() => setOpen(false), [])}
            title={t('copy_token.title')}
        />
        <GenerateButton action="token" record={props.record} callback={onSuccess}/>
    </>;
};

export default TokenBlock;
