import React, {useCallback, useState} from 'react';
import IPartner from 'interfaces/IPartner';
import Modal from '../Modal';
import {useTranslation} from 'react-i18next';
import {GenerateButton} from 'ReactAdminPages/Partner/GenerateButton';

interface ISecretBlockProps {
    record?: IPartner
}

export const SecretBlock = (props: ISecretBlockProps): React.ReactElement => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [secret, setSecret] = useState('');

    const onSuccess = useCallback(value => {
        setSecret(value);
        setOpen(true);
    }, []);

    return <>
        <Modal open={open}
            body={t('copy_secret.body', {secret})}
            onClose={useCallback(() => setOpen(false), [])}
            title={t('copy_secret.title')}
        />
        <GenerateButton action="secret" record={props.record} callback={onSuccess}/>
    </>;
};

export default SecretBlock;
