import React, {ReactElement} from 'react';
import {Button} from '@material-ui/core';
import RedirectToCashoutDetailsI from 'ReactAdminPages/Cashout/RedirectToCashoutDetailsI';

export const RedirectToCashoutDetails: React.FC<RedirectToCashoutDetailsI> = (
    {record, isDeposit}
): ReactElement => <Button
    href={`cashouts/${isDeposit ? record.cashout_id : record.id}`}>
    Details
</Button>;
