import * as React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

interface IPropsBackButton {
    basePath?: string
}

export const BackButton = (props: IPropsBackButton): React.ReactElement => <Button
    color="primary"
    component={Link}
    to={{pathname: props.basePath}}
>
    Back
</Button>;
