import {Pagination} from 'react-admin';
import React, {ReactElement} from 'react';

const TWENTY_FIVE_LINES = 25;
const FIFTY_LINES = 50;
const ONE_HUNDRED_LINES = 100;

export const ListPagination = (): ReactElement => <Pagination rowsPerPageOptions={[
    TWENTY_FIVE_LINES,
    FIFTY_LINES,
    ONE_HUNDRED_LINES
]} />;
