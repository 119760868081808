import React, {useCallback, useState} from 'react';
import {Create, required, TextInput,
    useNotify, useRedirect} from 'react-admin';
import {useTranslation} from 'react-i18next';
import Modal from 'ReactAdminPages/Modal';
import {SimpleFormLayout} from 'ReactAdminPages/FormLayout/SimpleFormLayout';

interface IProps {
    basePath?: string
}

export const PartnerCreate = (props: IProps): React.ReactElement => {
    const notify = useNotify();
    const redirect = useRedirect();
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({id: 1, secret: '', token: ''});

    const handleConfirm = useCallback(() => {
        setOpen(false);
        redirect('edit', props.basePath, data.id, {}, {record: {...data}});
    }, []);

    const onSuccess = useCallback(response => {
        setData(response.data);
        setOpen(true);
        notify('ra.notification.created');
    }, []);

    return <>
        <Modal open={open}
            body={t('copy_keys.body', {
                secret: data.secret,
                token: data.token
            })}
            onClose={handleConfirm}
            title={t('copy_keys.title')}
        />
        <Create {...props} onSuccess={onSuccess}>
            <SimpleFormLayout {...props}>
                <TextInput source="name" validate={required()}/>
                <TextInput source="email" validate={required()}/>
                <TextInput source="notify_url" validate={required()}/>
                <TextInput source="finish_url" validate={required()}/>
                <TextInput source="cancel_url" validate={required()}/>
            </SimpleFormLayout>
        </Create>
    </>;
};
