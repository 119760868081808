import React from 'react';
import {
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from 'react-admin';
import {SimpleFormLayout} from 'ReactAdminPages/FormLayout/SimpleFormLayout';

const BINS_PER_PAGE = 100;

export const BankBinForm = (props: unknown): React.ReactElement => <SimpleFormLayout {...props}>
    <TextInput disabled source="id" />
    <TextInput source="bin" validate={required()} />
    <ReferenceInput label="Bank Bin" source="bank_id" reference="banks" perPage={BINS_PER_PAGE}>
        <SelectInput optionText="name" />
    </ReferenceInput>
</SimpleFormLayout>;
