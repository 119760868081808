import * as React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import IBank from 'interfaces/IBank';

interface IPropsBankButton {
    record?: IBank
}

export const BankButtonToBins = (props: IPropsBankButton): React.ReactElement => props.record
    ? <Button
        color="primary"
        component={Link}
        to={{
            pathname: '/bank_bins',
            search: `filter=${JSON.stringify({bank_name: props.record.name, country_id: props.record.country_id})}`
        }}
    >
            REALTED BINS
    </Button>
    : null;
