import React, {ReactElement} from 'react';
import {Button} from '@material-ui/core';
import RedirectToCashoutDetailsI from 'ReactAdminPages/Cashout/RedirectToCashoutDetailsI';
import {Link} from 'react-router-dom';

export const RedirectToPaymentLogs: React.FC<RedirectToCashoutDetailsI> = (
    {record, isDeposit}
): ReactElement => <Button color="primary" component={Link}
    to={{
        pathname: `/payment_logs/${isDeposit ? 'deposits' : 'cashouts'}`,
        search: `filter=${JSON.stringify({payment_id: record.id})}`
    }}>
        Payment Logs
</Button>;
